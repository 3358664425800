import React, {useState, useEffect, useContext} from "react";
import {Col, Row, Container} from "react-bootstrap";
import { SavingsCalculatorWrapper } from "./SavingsCalculator.styles";
import InputField  from "./components/CalculatorValueInput/CalculatorValueInput";
import InputToggle from "./components/InputToggle/InputToggle";
import CurrencySelector from "./components/CurrencySelector/CurrencySelector";
import SyncedSliders from "./components/SyncedSliders/SyncedSliders";
import { stringToNumber, percentageOrCurrency } from "./utils/helperFunctions";
import { InputValuesProvider, useInputValues, InputValuesContext } from "./InputValuesContext/InputValuesContext";
import FieldDescriptionPopup from "./components/FieldDescriptionPopup/FieldDescriptionPopup";
import HubSpotFormPopup from "./components/HubspotForm/HubspotForm";
import { POPUP_DATA } from "./data/popupDescriptions.data";

const SavingsCalculator = () => {

    const {updateInputValue} = useInputValues();
    const [activeCurrency, setActiveCurrency] = useState("$");
    const [hubspotFormToggle, setHubspotFormToggle] = useState(false);

    useEffect(() => {
        updateInputValue('currency', activeCurrency);
    }, [activeCurrency]);

    const currencyChange = (currency) => {
        setActiveCurrency(currency);
        updateInputValue('currency', currency);
    };

    const openHubspotForm = () => {
        updateInputValue();
        setHubspotFormToggle(true);
    };
    const closeHubspotForm = () => {
        setHubspotFormToggle(false);
    };

    return (
    <SavingsCalculatorWrapper>
        <HubSpotFormPopup isVisible={hubspotFormToggle} onClose={closeHubspotForm}/>
        <div className="mobile-calculator-summary">
            <div className="totals-summary">
                <div className="text-white small-text">
                    Your potential Annual Benefit
                </div>
                <div className="mobile-totals-container">
                    <span className="text-white large-text mb-1 summary-total">
                        $0
                    </span>
                    <span className="text-white large-text divider">/</span>
                    <span className="text-white large-text mb-1 percentage-improvement">
                        0%
                    </span>
                </div>
            </div>
            <div className="button-container">
                <button className="requestAssessment" onClick={openHubspotForm}>Contact Us</button>
            </div>
        </div>
        <Container className="py-3 my-5">
            <div className="calculator-fields-wrapper px-4 px-sm-0">
                <CurrencySelector
                    onRadioChange={currencyChange}
                />
                <SyncedSliders 
                    currency={activeCurrency}
                />
                <div className="calculator-field-group">
                    <div className="calculator-field-container">
                        <div className="calculator-field-description-container">
                            <div className="calculator-field-label">
                                What is the split of eCommerce vs face to face payments that you process?
                            </div>
                            <FieldDescriptionPopup
                                content={POPUP_DATA["ecommerce-split"]}
                            />    
                        </div>
                        <InputField
                            minValue={0}
                            maxValue={100}
                            startingValue={60}
                            percentage={true}
                            sliderLabels={['0', '20', '40', '60', '80', '100']}
                            fieldId='ecomSplit'
                        />
                    </div>
                    <div className="calculator-field-container">
                        <div className="calculator-field-description-container">
                            <div className="calculator-field-label">
                                Do you process card payments with a Travel Merchant Category Code?
                            </div>
                            <FieldDescriptionPopup
                                content={POPUP_DATA["travel-mmc"]}
                            />    
                        </div>
                        <InputToggle 
                            fieldId="travelMmc"
                        />
                    </div>
                    <div className="calculator-field-container">
                        <div className="calculator-field-description-container">
                            <div className="calculator-field-label">
                                What is your Acceptance Rate?
                            </div>
                            <FieldDescriptionPopup
                                content={POPUP_DATA["acceptance-rate"]}
                            />    
                        </div>
                        <InputField
                            minValue={50}
                            maxValue={100}
                            firstSliderBreakpoint={55}
                            maxTicks={50}
                            startingValue={80}
                            percentage={true}
                            sliderLabels={['50', '60', '70', '80', '90', '100']}
                            fieldId='acceptanceRate'
                        />
                    </div>
                    <div className="calculator-field-container">
                        <div className="calculator-field-description-container">
                            <div className="calculator-field-label">
                                Do you use multiple acquirers for card payments?
                            </div>
                            <FieldDescriptionPopup
                                content={POPUP_DATA["more-than-one-acquirer"]}
                            />    
                        </div>
                        <InputToggle 
                            fieldId="moreThanOneAcquirer"
                            checked={true}
                        />
                    </div>
                </div>
            </div>
            <div className="calculator-summary-wrapper">
                <div className="calculator-summary-container text-white">
                    <div className="cost-savings d-flex justify-content-between">
                        <div className="summary-label">
                            <div>Cost Savings</div>
                            <div className="small-text">(cost routing)</div>
                        </div>
                        <div className="summary-value">
                            $0
                        </div>
                    </div>
                    <hr />
                    <div className="revenue-improvement d-flex justify-content-between">
                        <div className="summary-label">
                            <div>Revenue Improvement</div>
                            <div className="small-text">(cascading + acceptance increase)</div>
                        </div>
                        <div className="summary-value">
                            $0
                        </div>
                    </div>
                    <hr />
                    <div className="total-improvement d-flex justify-content-between align-items-center">
                        <div className="summary-label">
                            <div>Total</div>
                        </div>
                        <div className="summary-value">
                            $0
                        </div>
                    </div>
                    <hr />
                    <div className="totals-summary">
                        <div className="text-white mb-1 mt-4">
                            Your potential Annual Benefit
                        </div>
                        <div className="text-white large-text mb-1 summary-total">
                            $0
                        </div>
                        <div className="text-white semi-bold mb-1">
                            or
                        </div>
                        <div className="text-white mb-4">
                            <div className="large-text percentage-improvement">0%</div>
                            <span className="small-text pl-2">(Total/Annual Value)</span>
                        </div>
                        <button className="requestAssessment" onClick={openHubspotForm}>Request your <span className="text-white">FREE</span> payment optimisation assessment</button>
                    </div>
                    
                </div>
            </div>
        </Container>
        <div dangerouslySetInnerHTML={{ __html: `
        <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>
        <script>
        hbspt.forms.create({
            region: "eu1",
            portalId: "141862351",
            formId: "8c31da6e-a395-471c-8689-3b1fd3b0b7c0",
            target: '#hubspotForm'
        });
        </script>` }} />
    </SavingsCalculatorWrapper>
)};

export default SavingsCalculator;