import styled from "styled-components";
import { fonts, colors } from "../../../utils/styles"

export const SavingsCalculatorWrapper = styled.section`

    //position: relative;
    @media (max-width: 575px) {
        top: -70px;
    }

    .container{
        display: grid;
        gap: 90px;
        grid-template-columns: 1fr 415px;
        font-family: 'poppins';

        @media screen and (max-width: 991px) {
            grid-template-columns: 1fr;
            gap: 70px;
        }

        .calculator-summary-container{
            background-color: ${colors.secondaryBlue3};
            border-radius: 24px;
            padding: 29px 29px 38px;
            box-shadow: 0px 10px 20px 0px rgba(20, 64, 86, 0.25);
            position: sticky;
            top: 30px;

            @media screen and (max-width: 991px) {
                width: 100%;
                max-width: 550px;
                margin: auto;
            }

            @media (max-width: 550px) {
                border-radius: 0;
            }

            .summary-label{
                font-size: 14px;
                line-height: 21px;

                .small-text{
                    font-size: 10px;
                    line-height: 15px;
                }
            }

            .summary-value{
                font-size: 20px;
                line-height: 30px;
            }
            
            .percentage-improvement,.summary-total{
                display: inline-block;
                scale: 1;
                transition: scale linear 0.3s;

                &.scale-total{
                    scale: 1.1;
                }
            }

            .summary-value, .semi-bold, .large-text{
                font-weight: 600;
            }

            hr{
                border: unset;
                height: 1px;
                background-color: ${colors.secondaryBlue2};
            }

            .large-text{
                font-size: 32px;
                line-height: 48px;
            }

            button.requestAssessment{
                background-color: ${colors.secondaryBlue2};
                border-radius: 24px;
                padding: 16px 36px;
                color: ${colors.primaryBlue};
                font-size: 18px;
                line-height: 25px;
                font-weight: 600;
                border: unset;
                box-shadow: 0px 4px 4px 0px #00000040;
                transition: scale, linear 0.2s;

                &:hover{
                    scale: 1.04;
                }

                .text-white{
                    text-decoration: underline;
                }
            }
        }

        .calculator-fields-wrapper, .calculator-field-group{
            display: grid;
            grid-template-columns: 1fr;
            gap: 70px;

            @media (max-width: 575px) {
                gap: 50px;
            }

            .calculator-field-description-container{
                display: grid;
                grid-template-columns: 1fr 24px;
                gap: 50px;
            }

            .calculator-field-label{
                font-weight: 600;
                color: ${colors.primaryBlue};
                margin-bottom: 25px;
            }
        }
        
    }

    .mobile-calculator-summary{
        background-color: ${colors.secondaryBlue3};
        font-family: 'Poppins';
        padding: 8px 16px;
        display: grid;
        grid-template-columns: 1fr 100px;
        gap: 5px 20px;
        height: 70px;
        top: 0;
        position: sticky;
        z-index: 999;

        @media screen and (min-width: 992px) {
            display: none;
        }

        .large-text{
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
        }

        .button-container{
            display: flex;
            align-items: center;

            button.requestAssessment{
                background-color: ${colors.secondaryBlue2};
                border-radius: 24px;
                padding: 8px 12px;
                color: ${colors.primaryBlue};
                font-size: 13px;
                line-height: 18px;
                font-weight: 600;
                border: unset;
                box-shadow: 0px 4px 4px 0px #00000040;
                transition: scale, linear 0.2s;

                &:hover{
                    scale: 1.04;
                }

                .text-white{
                    text-decoration: underline;
                }

                @media screen and (max-width: 400px) {
                    margin: auto;
                }
            }    
        }

        .mobile-totals-container{
            display: inline-block;
            scale: 1;
            transition: scale linear 0.3s;

            &.scale-total{
                scale: 1.08;
            }
        }

        @media screen and (max-width: 400px) {
            grid-template-columns: 1fr;
            height: unset;
            padding: 12px 16px;
            height: 120px;
            text-align: center;
        }
        
    }

    .small-text{
        font-size: 12px;
        line-height: 18px;
    }
`